<template>
  <section class="flow_skintype_1">
    <div class="flow_skintype_1_container">
      <div class="question">
        <p>Q</p>
        <h1>あなたの顔の輪郭はどの形が近いですか？</h1>
      </div>
      <div class="answer_btn_container">
        <div class="answer_btn">
          <img
            :src="
              IMAGE_ROOT_URL
                + 'flow-images/makeup/face-parts-placement/face_balane.png'
            "
          >
          <a @click="next(false, 53, 0, false, 54)" />
          <a @click="next(false, 53, 1, false, 54)" />
          <a @click="next(false, 53, 2, false, 54)" />
          <a @click="next(false, 53, 3, false, 54)" />
          <a @click="next(false, 53, 4, false, 54)" />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'FacePartsPlacement',
  data(){
    return {
      IMAGE_ROOT_URL: process.env.VUE_APP_BASE_URL
    }
  },
  methods:{
    next: function(is_start, q_number, a_number, finished, next_number){
          this.$emit('catchMessage', is_start, q_number, a_number, finished, next_number);
    }
  }   
}
</script>
<style scoped>
.flow_skintype_1 {
    background-color: #ffffff;
    font-size: 14px;
}
.flow_skintype_1_container {
    padding: 30px 0 40px 0;
}
.question {
    padding-bottom: 35px;
    display: flex;
}
.question p {
    font-size: 24px;
    font-weight: bold;
    padding-right: 10px;
}
h1 {
    font-size: 20px;
}
.answer_btn_container {
    margin: 0 auto;
}
.answer_btn {
    width: 100%;
    position: relative;
}
.answer_btn img {
    width: 100%;
    object-fit: cover;
}
.answer_btn a:nth-of-type(1) {
    position: absolute;
    width: 36%;
    height: 36%;
    top: 0;
    left: 0;
}
.answer_btn a:nth-of-type(2) {
    position: absolute;
    width: 36%;
    height: 36%;
    top: 0;
    right: 0;
}
.answer_btn a:nth-of-type(3) {
    position: absolute;
    width: 34%;
    height: 34%;
    top: 33%;
    left: 33%;
}
.answer_btn a:nth-of-type(4) {
    position: absolute;
    width: 36%;
    height: 36%;
    bottom: 0;
    left: 0;
}
.answer_btn a:nth-of-type(5) {
    position: absolute;
    width: 36%;
    height: 36%;
    bottom: 0;
    right: 0;
}
</style>
