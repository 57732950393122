<template>
  <section class="flow_skintype_1">
    <div class="flow_skintype_1_container">
      <div class="question">
        <p>Q</p>
        <h1>あなたの顔の輪郭はどの形が近いですか？</h1>
      </div>
      <div class="answer_btn_container">
        <div class="answer_btn">
          <a @click="next(false, 52, 0, false, 53)">
            <img
              :src="
                IMAGE_ROOT_URL
                  + 'flow-images/makeup/face-shape/oval.png'
              "
            >
            <p>卵型</p>
          </a>
          <a @click="next(false, 52, 1, false, 53)">
            <img
              :src="
                IMAGE_ROOT_URL
                  + 'flow-images/makeup/face-shape/round.png'
              "
            >
            <p>丸型</p>
          </a>
          <a @click="next(false, 52, 2, false, 53)">
            <img
              :src="
                IMAGE_ROOT_URL
                  + 'flow-images/makeup/face-shape/oblong.png'
              "
            >
            <p>面長</p>
          </a>
          <a @click="next(false, 52, 3, false, 53)">
            <img
              :src="
                IMAGE_ROOT_URL
                  + 'flow-images/makeup/face-shape/diamond.png'
              "
            >
            <p>ベース型</p>
          </a>
          <a @click="next(false, 52, 4, false, 53)">
            <img
              :src="
                IMAGE_ROOT_URL
                  + 'flow-images/makeup/face-shape/triaglar.png'
              "
            >
            <p>三角</p>
          </a>
          <a @click="next(false, 52, 5, false, 53)">
            <img
              :src="
                IMAGE_ROOT_URL
                  + 'flow-images/makeup/face-shape/square.png'
              "
            >
            <p>四角</p>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'FaceShape',
  data(){
    return {
      IMAGE_ROOT_URL: process.env.VUE_APP_BASE_URL
    }
  },
  methods:{
    next: function(is_start, q_number, a_number, finished, next_number){
          this.$emit('catchMessage', is_start, q_number, a_number, finished, next_number);
    }
  }
}
</script>
<style scoped>
.flow_skintype_1 {
    background-color: #ffffff;
    font-size: 14px;
}
.flow_skintype_1_container {
    padding: 30px 0 40px 0;
}
.question {
    padding-bottom: 35px;
    display: flex;
}
.question p {
    font-size: 24px;
    font-weight: bold;
    padding-right: 10px;
}
h1 {
    font-size: 20px;
}
.answer_btn_container {
    margin: 0 auto;
}
.answer_btn {
    width: 100%;
}
.answer_btn a {
    display: inline-block;
    width: 48.8%;
    border: #FADAD2 solid 1px;
    margin-bottom: 1px;
    padding-bottom: 5px;
}
.answer_btn a:nth-of-type(2n){
  margin-left: 1px;
}
.answer_btn img {
    width: 100%;
    object-fit: cover;
}
.answer_btn p {
    text-align: center;
}
</style>
