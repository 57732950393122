<template>
  <section class="flow_skintype_1">
    <div class="flow_skintype_1_container">
      <div class="question">
        <p>Q</p>
        <h1>彫りは深いですか？</h1>
      </div>
      <div class="answer_btn_container">
        <div class="answer_btn">
          <a @click="next(false, 55, 0, false, 56)">
            <img
              :src="
                IMAGE_ROOT_URL
                  + 'flow-images/makeup/profile/shape-chiseled-face/deeply_carved.png'
              "
            >
            <p>深い</p>
          </a>
          <a @click="next(false, 55, 1, false, 56)">
            <img
              :src="
                IMAGE_ROOT_URL
                  + 'flow-images/makeup/profile/shape-chiseled-face/shallow_carved.png'
              "
            >
            <p>浅い</p>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'FaceChiseled',
  data(){
    return {
      IMAGE_ROOT_URL: process.env.VUE_APP_BASE_URL
    }
  },
  methods:{
    next: function(is_start, q_number, a_number, finished, next_number){
          this.$emit('catchMessage', is_start, q_number, a_number, finished, next_number);
    }
  }
}
</script>
<style scoped>
.flow_skintype_1 {
    background-color: #ffffff;
    font-size: 14px;
}
.flow_skintype_1_container {
    padding: 30px 0 40px 0;
}
.question {
    padding-bottom: 35px;
    display: flex;
}
.question p {
    font-size: 24px;
    font-weight: bold;
    padding-right: 10px;
}
h1 {
    font-size: 20px;
}
.answer_btn_container {
    margin: 0 auto;
}
.answer_btn {
    width: 100%;
    display: flex;
}
.answer_btn a {
    display: inline-block;
    border: #FADAD2 solid 1px;
    padding-bottom: 5px;
}
.answer_btn a:nth-of-type(2n){
  margin: 0 2px;
}
.answer_btn img {
    width: 100%;
    object-fit: cover;
}
.answer_btn p {
    text-align: center;
}
</style>
