<template>
  <section class="flow_skintype_1">
    <div class="flow_skintype_1_container">
      <div class="question">
        <p>Q</p>
        <h1>肌コンディションを教えてください</h1>
      </div>
      <div class="answer_btn_container">
        <div class="answer_btn">
          <a @click="next(false, 50, 0, false, 51)">
            健康肌
          </a>
        </div>
        <div class="answer_btn">
          <a @click="next(false, 50, 1, false, 51)">
            色むら*
          </a>
        </div>
        <div class="answer_btn">
          <a @click="next(false, 50, 2, false, 51)">
            小ジワ
          </a>
        </div>
        <div class="answer_btn">
          <a @click="next(false, 50, 3, false, 51)">
            毛穴
          </a>
        </div>
        <p>*「くすみ、そばかす、くま」なども含まれます。</p>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'SkinCondition',
  methods:{
    next: function(is_start, q_number, a_number, finished, next_number){
          this.$emit('catchMessage', is_start, q_number, a_number, finished, next_number);
    }
  }
}
</script>
<style scoped>
.flow_skintype_1 {
    background-color: #ffffff;
    font-size: 14px;
}
.flow_skintype_1_container {
    padding: 30px 0 40px 0;
}
.question {
    padding-bottom: 35px;
    display: flex;
}
.question p {
    font-size: 24px;
    font-weight: bold;
    padding-right: 10px;
}
h1 {
    font-size: 20px;
}
.answer_btn_container {
    max-width: 95%;
    margin: 0 auto;
}
.answer_btn {
    text-align: center;
    padding-bottom: 20px;
}
.answer_btn a {
    display: block;
    font-size: 18px;
    padding: 16px 25px;
    border-radius: 100vh;
    border: #707070 solid 1px;
}
.answer_btn_container p {
    padding-bottom: 20px;
}
</style>
