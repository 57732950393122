<template>
  <section class="flow_skintype_1">
    <div class="flow_skintype_1_container">
      <div class="question">
        <p>Q</p>
        <h1>直近の診断であなたの肌は以下の通りでした。こちらでご提案をしてよろしいでしょうか？</h1>
      </div>
      <div class="skintype_img_container">
        <img
          :src="
            SKIN_IMAGE_ROOT
              + 'flow-images/skin-type-diag/result-images/'
              + latest_skin_type_id
              + '/'
              + latest_skin_type_id
              + '_1.png'
          "
        >
      </div>
      <div class="answer_btn_container">
        <div class="answer_btn">
          <a @click="next(false, 1, latest_skin_type_id, false, 2)">
            はい
          </a>
        </div>
        <div class="answer_btn">
          <router-link to="/flow/skin-type-diag">
            もう一度肌質診断を受ける
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'SkinTypeConfirm',
  props: {
    latest_skin_type_id: {
      type: Number,
      default: 0
    }
  },
  data(){
    return {
      SKIN_IMAGE_ROOT: process.env.VUE_APP_BASE_URL
    }
  },
  methods: {
    next: function(is_start, q_number, a_number, finished, next_number){
          this.$emit('catchMessage', is_start, q_number, a_number, finished, next_number);
    }
  }
}
</script>
<style scoped>
.question {
    padding: 70px 0 35px 0;
    display: flex;
}
.question p {
    font-size: 24px;
    font-weight: bold;
    padding-right: 10px;
}
h1 {
    font-size: 20px;
}
img {
    width: 100%;
    object-fit: cover;
}
.skintype_img_container{
    padding-bottom: 30px;
}
.answer_btn_container {
    padding-bottom: 40px;
    max-width: 95%;
    margin: 0 auto;
}
.answer_btn {
    text-align: center;
    padding-bottom: 20px;
}
.answer_btn a {
    display: block;
    font-size: 17.5px;
    padding: 16px 25px;
    border-radius: 100vh;
    border: #707070 solid 1px;
    text-decoration: none;
    color: #707070;
}
.concierge_img_container {
    position: relative;
    padding: 50px 0 10px;
}
.concierge_profile {
    padding: 10px 0 20px 0;
}
.profile_btn_container {
    text-align: center;
    padding-bottom: 30px;
}
.profile_btn {
    color: #707070;
    background-color: #FADAD2;
    padding: 11px 21px;
    font-size: 14px;
    display: inline-block;
}
.concierge_img {
    width: 100%;
    object-fit: cover;
}
.right_btn {
    position: absolute;
    height: 23px;
    top: 50%;
    right: 0;
}
.selectConcierge_btn {
    text-align: center;
    font-size: 22px;
    display: block;
    width: 100%;
    border-radius: 100vh;
    padding: 15px 0;
    background-color: #F29F8F;
    color: #ffffff;
    margin-bottom: 90px;
}
img, embed, object, iframe {
    vertical-align: bottom;
}
.progressBar {
    text-align: right;
    padding-bottom: 70px;
}
progress {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #EEEEEE;
    border-radius: 100vh;
    width: 200px;
    height: 10px;
    vertical-align: baseline;
}
</style>
